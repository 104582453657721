import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Header from "../Header/Header";
import {
 HeroContainer,
 HeroWrapper,
 HeroLeft,
 HeroRight,
 Image,
 ScrollDown,
 ScrollLink,
} from "./HeroElements";
import { TypeAnimation } from 'react-type-animation';
import { useSpring, animated } from '@react-spring/web';

function Hero() {
 const [isOpen, setIsOpen] = useState(false);
 const [showSubtitle, setShowSubtitle] = useState(false);
 const [showScrollDown, setShowScrollDown] = useState(false);

 const toggle = () => {
   setIsOpen(!isOpen);
 };

 const props = useSpring({
   opacity: 1,
   from: { opacity: 0 },
   config: { duration: 1000 },
 });

 return (
   <main>
     <Dropdown isOpen={isOpen} toggle={toggle} />
     <Header toggle={toggle} />
     <HeroContainer>
       <HeroWrapper>
         <HeroLeft>
           <animated.div style={props}>
             <TypeAnimation
               cursor={false}
               sequence={[
                'Hi, I\'m Harsh.',
                () => setShowSubtitle(true)
               ]}
               speed={{ type: "keyStrokeDelayInMs", value: 150 }}
               wrapper="h1"
               repeat={0}
             />
             {showSubtitle &&
               <TypeAnimation
                cursor={true}
                sequence={[
                  500,
                  'A Software Developer.',
                  1000,
                  'A explorer by heart.',
                  1000,
                  'A problem solver.',
                  1000,
                  'An innovative thinker.',
                  1000,
                  'A....',
                  1000,
                  'A.... cool guy?',
                  1000,
                  "Ok...",
                  1000,
                  "Ok... I'm running out of ideas...",
                  1000,
                  "Uhh...",
                  1000,
                  "Uhh... you can scroll down to see my projects now...",
                  300,
                  () => setShowScrollDown(true),
                  1000,
                  "Seriously, my projects are really cool, go check them out!",
                  1000,
                  "You're uh...",
                  1000,
                  "You're uh... still here?",
                  1000,
                  "Ok, this has been fun, but I'm gonna restart the loop now...",
                  1000,
                  "See ya! :)",
                  500,
                ]}
                speed={50}
                deletionSpeed={65}
                wrapper="h5"
                repeat={Infinity}
               />
             }
           </animated.div>
         </HeroLeft>
         <HeroRight>
           <animated.div style={props}>
             <Image
               src="/image 2.png"
               alt="man-svgrepo"
             />
           </animated.div>
         </HeroRight>
       </HeroWrapper>
       {showScrollDown &&<animated.div style={props}>
       <ScrollDown to="projects" id="scrollDown">
         <ScrollLink>
           Scroll down
           <img
             src="/scroll-down.svg"
             alt="scroll-down"
           />
         </ScrollLink>
       </ScrollDown>
       </animated.div>}
     </HeroContainer>
   </main>
 );
}

export default Hero;