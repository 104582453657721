// --------------------------------------- Project List
export const ProjectList = [
  {
    img: '/zapmap.png',
    title: 'ZapMap',
    description:
      'A wallet-based EV charge station locator application with slot booking functionality.',
    tech_stack: ['Flutter', 'Flask', 'MongoDB', 'Google Maps API'],
    github_url: 'https://github.com/Harsh-H-Shah/ZapMap',
    demo_url: '',
  },
  {
    img: '/zerowaste.png',
    title: 'Zero Waste Dashboard 3.0',
    description:
      'An interactive waste generation dashboard for Mumbai, India where users can navigate data at 4 hierarchical levels.',
    tech_stack: ['React', 'Flask', 'postgreSQL', 'Material UI'],
    github_url: 'https://github.com/Harsh-H-Shah/zerowaste',
    demo_url: 'https://effulgent-froyo-ab5141.netlify.app/',
  },
  {
    img: '/rashipay.png',
    title: 'RashiPay Smart India Hackathon 2022',
    description:
      'A wallet-based offline digital payment application for peer-to-peer payments using Bluetooth or WiFi Direct.',
    tech_stack: ['React Native', 'Tailwind', 'NodeJs', 'Android', 'MySQL'],
    github_url: '',
    demo_url: '',
  },
  {
    img: '/alphatrading.png',
    title: 'Alpha Trading',
    description:
      'A Stock trading web application for risk-free practice on NSE stocks with pseudo money.',
    tech_stack: ['ReactJs', 'NodeJs', 'MongoDB'],
    github_url: 'https://github.com/Harsh-H-Shah/Alpha-trading',
    demo_url: 'https://alpha-trading.netlify.app/',
  },
  {
    img: '/codehub.png',
    title: 'CodeHub',
    description: 'An E-tutorial website for learning full-stack development.',
    tech_stack: ['ReactJS', 'TailwindCSS', 'Firebase'],
    github_url: 'https://github.com/Harsh-H-Shah/codehub',
    demo_url: 'https://code-hub-2021.netlify.app/',
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg',
    name: 'HTML',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg',
    name: 'CSS',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg',
    name: 'JavaScript',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg',
    name: 'ReactJS',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg',
    name: 'NextJs',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg',
    name: 'Typescript',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg',
    name: 'Git',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg',
    name: 'React Native',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg',
    name: 'NodeJS',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg',
    name: 'Firebase',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg',
    name: 'MongoDB',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg',
    name: 'Python',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg',
    name: 'Flask',
  },
  {
    img: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg',
    name: 'Figma',
  },
];
