import React, { useState } from "react";
import { ContactWrapper, Email } from "./ContactElements";
import { MdContentCopy } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import Zoom from '@mui/material/Zoom';

import { useSpring, animated } from '@react-spring/web';

function Contact() {
 const [showTooltip, setShowTooltip] = useState(false);
 const copyToClipboard = () => {
   navigator.clipboard.writeText("hi@harshs.me");
   setShowTooltip(true);
   setTimeout(() => {
     setShowTooltip(false);
   }, 700);
 };

 const props = useSpring({
   opacity: 1,
   from: { opacity: 0 },
   config: { duration: 1000 },
 });

 return (
   <ContactWrapper id="contact">
     <div className="Container">
       <div className="SectionTitle">Get In Touch</div>
       <animated.div style={props}>
         <div className="BigCard">
           <Email>
             <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', rowGap: '10px', flexWrap: 'wrap', justifyContent: 'center' }} >
               <span>hi@harshs.me</span>
               <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={showTooltip}
                onClose={() => setShowTooltip(false)}
                title="Copied!"
                TransitionComponent={Zoom}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="bottom"
               >
                <IconButton onClick={copyToClipboard} >
                  <MdContentCopy size={25} style={{ cursor: 'pointer', color: "#151418" }}/>
                </IconButton>
               </Tooltip>
             </div>
             <a
               className="btn PrimaryBtn btn-shadow"
               href="mailto:hi@harshs.me"
               target="_blank"
               rel="noopener noreferrer"
             >
               Send Email
             </a>
           </Email>
         </div>
       </animated.div>
     </div>
   </ContactWrapper>
 );
}

export default Contact;