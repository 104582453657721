import React from 'react';
import { stackList } from '../../data/ProjectData';
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from './AboutElements';
import { useSpring, animated } from '@react-spring/web';
function About() {
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <animated.div style={props}>
            <Image src="/image 3.png" alt="About image" />
          </animated.div>
          <div className="AboutBio">
            <animated.div style={props}>
              Hey! My name is <strong>Harsh Shah</strong>. I'm a tech enthusiast
              with a knack for problem-solving and an insatiable curiosity that
              drives me to explore various technologies, irrespective of the
              domain. My journey in technology started just like any adventure -
              with curiosity and a passion for discovery. Whether I'm working on
              a web development project or dabbling in UI/UX design, the thrill
              of solving problems and learning new things keeps me going.
            </animated.div>

            <br />
            <br />

            <animated.div style={props}>
              The world of technology is vast and ever-changing, and I'm always
              eager to dive into new areas. One such deep dive led me to the
              fascinating intersection of machine learning and cybersecurity.
              This discovery has led me to dedicate significant time and energy
              towards understanding and innovating in this space. But don't be
              fooled, my interests don't stop there. I've also worked on
              projects that span other areas like blockchain and system
              security. I see every challenge as an opportunity to learn
              something new and to further hone my problem-solving skills.
            </animated.div>

            <br />
            <br />

            <animated.div style={props}>
              Working collaboratively within a team towards a shared objective
              has been an incredibly rewarding and unique experience for me. I
              am eager to continue exploring exciting projects in the future,
              with a particular interest in web development and cross-platform
              mobile development.
              <div className="tagline2">
                I have become confident using the following technologies:
              </div>
            </animated.div>

            <Technologies>
              {stackList.map((stack, index) => (
                <animated.div key={index} style={props}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </animated.div>
              ))}
            </Technologies>
          </div>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
